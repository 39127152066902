.container {
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
  padding: 20px;
  max-width: 1600px;
  margin: 0 auto;
}

.card {
  background: #f5f5f5;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: 280px;
  background: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  animation: pulse 1.5s ease-in-out infinite;
}

.brandCircle {
  position: absolute;
  bottom: 16px;
  left: 16px;
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brandInner {
  width: 32px;
  height: 32px;
  background: #e0e0e0;
  border-radius: 50%;
  animation: pulse 1.5s ease-in-out infinite;
}

.contentWrapper {
  padding: 16px;
}

.priceSection {
  margin-bottom: 12px;
}

.priceWrapper {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.mainPrice {
  height: 24px;
  width: 80px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s ease-in-out infinite;
}

.includedPrice {
  height: 16px;
  width: 60px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s ease-in-out infinite;
}

.title {
  height: 20px;
  width: 100%;
  background: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 12px;
  animation: pulse 1.5s ease-in-out infinite;
}

.sizeWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sizeLabel {
  height: 16px;
  width: 40px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s ease-in-out infinite;
}

.sizeValue {
  height: 16px;
  width: 30px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1200px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 16px;
  }

  .imageWrapper {
    height: 240px;
  }
}

@media (max-width: 480px) {
  .grid {
    grid-template-columns: 1fr;
  }
}
