.popularCategories {
  /* margin-top: 50px;
  margin-bottom: 50px; */
  /* margin-left: 32px; */
  /* margin-right: 32px; */
  text-align: center;
  color: var(--colorGrey900);
}
.popularCategories .grid-container {
  display: flex;
  flex-wrap: wrap;
  /* space between evenly 5 columns */
  gap: 16px;
  justify-content: space-evenly;
  align-items: center;
}

.popularCategories.circle a {
  width: 180px; /* diameter of the circle */
  height: 280px; /* diameter of the circle */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  /* overflow: hidden; */
}
.popularCategories.circle a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 8px; */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

.popularCategories.circle a img:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

@media (max-width: 770px) {
  .grid-container {
    /* 2 columns */
    grid-template-columns: repeat(2, 1fr);
  }
}

.latestProductsSection {
  background-color: white;
}
