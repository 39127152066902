@import '../../styles/customMediaQueries.css';
.listingsContainer {
  composes: clearfix from global;
  /* border-top: 1px solid var(--colorGrey100); */
  padding-bottom: 23px;
}

.popularCategoriesSection {
  margin-top: 50px;
  margin-bottom: 50px;
}

.listingsTitle {
  color: var(--colorGrey700);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }
  @media (--viewportLarge) {
    margin-top: 56px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    /* border-top: 1px solid var(--colorGrey100); */
  }
}

.listings {
  /* margin: 0; */
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;

  gap: 10px;

  @media (--viewportSmall) {
    gap: 0px;
  }
}

.listing {
  width: 100%;

  /* Single column in mobile */
  margin-top: 34px;

  @media (--viewportSmall) {
    /* Five columns in desktop */
    width: calc(20% - 12px);
    margin-bottom: 36px;

    margin-right: 12px;
    /* Vertical space */
    margin-top: 0;
  }

  @media only screen and (max-width: 600px) {
    width: calc(50% - 12px);
  }
}

.latestProductsSection {
  background-color: white;
  margin: 50px 0 0 0;
}

.latestProductsSection h3 {
  text-align: center;
  color: black;
}

.latestProductsSection .buttonContainer a {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.buttonContainer {
  width: 100%;
  text-align: center;
}

.runOfSiteCustom {
  background-color: #d2c8bf;
  height: 50px;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.runOfSiteCustom span {
  margin-left: 15px;
  text-transform: uppercase;
}

.runOfSiteTwo {
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); */
  /* border: 1px solid rgba(0, 0, 0, 0.15); */
  background-color: #d2c8bf;
  overflow: hidden;
  position: relative;
  /* width: 100%; */
  height: 50px;
  padding: 10px;
  /* height: auto; */
  /* Full width of its parent */
  white-space: nowrap;
  font-weight: 600;
}

.runOfSiteTwo div {
  position: absolute;
  width: 100%;
  color: white;
  height: 100%;
  margin: 0;
  display: inline-block;
  /* line-height: 50px; */
  /* text-align: center; */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: scroll-left 2s linear infinite;
  -webkit-animation: scroll-left 2s linear infinite;
  animation: scroll-left 20s linear infinite;
}

.runOfSiteTwo span {
  margin-left: 15px;
}

@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.caro {
  width: 100vw;
  /* max-width: 100vw; */
  z-index: 1;
  position: relative;
  overflow: hidden;

  /* height: 100%; */
}

.caroImage img {
  /* object-fit: cover; */
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}

.listingCaro {
  width: 100vw;
  gap: 24px;
}

.caroImageDesktop {
  display: block;
}

.caroImageMobile {
  display: none;
}

.orangeCardSection {
  width: 100%;
  min-height: 600px;
  background-image: url('~/public/static/blocks/Feature01.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center; /* centers horizontally */
  align-items: center; /* centers vertically */
}

.orangeCardSection > div {
  display: flex;
  justify-content: center; /* centers horizontally */
  align-items: center; /* centers vertically */
  width: 100%; /* ensures the outer div takes the full width */
  /* height: 100%; ensures the outer div takes the full height */
  background-color: var(--marketplaceColor);
  width: 50%;

  padding: 30px;
  border-radius: 20px;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
}

.orangeCardSection > div > div {
  /* margin: auto auto; */

  text-align: center;
  color: black;
}

.orangeCardSection > div > div h3 {
  font-weight: bolder;
  margin-bottom: 0;
}

.orangeCardSection > div > div p {
  /* font-size: 0.7rem; */
  /* font-weight: bold; */
  max-width: 60%;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;

  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
}

.newButtonStyle {
  display: inline-block;
  border: 0;
  background-color: black;
  color: var(--marketplaceColor);
  padding: 5px 20px;
  border-radius: 20px;
  text-transform: capitalize;
  font-weight: bolder;
  font-size: 12px;
}

.newButtonStyle:hover {
  text-decoration: none;
  color: white;
}

.infoSection {
  width: 100%;
  display: flex;
  height: 500px;
  margin-top: 50px;
}

.infoSection > div:nth-child(1) {
  width: 50%;
  padding: 20px;
  background-image: url('~/public/static/blocks/Brand-Legacy.jpg');
  background-size: cover;
  background-position: center;
}

.infoSection div:nth-child(2) {
  width: 50%;
  background-color: rgb(148, 80, 238);
  height: 500px;
  color: black;
}

.infoSection div:nth-child(2) > div {
  /*
  center the content both horizontally and vertically
  */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 24px;
}

.infoSection div:nth-child(2) > div h2 {
  font-weight: bolder;
  margin-bottom: 0;
}

.infoSection div:nth-child(2) > div > div {
  text-align: left;
  width: 60%;
  margin-right: auto;

  @media only screen and (max-width: 600px) {
    width: 80%;
  }
}

.popularBrands {
  background-color: white;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* get the next seciton after .popularBrands */
.popularBrands + section {
  background-color: var(--colorGrey) !important;
}

.popularBrands h3 {
  color: black;
}

.popularBrandsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.popularBrandsContainer div {
  flex: 1; /* Adjusts the basis and grows the items to fit evenly */
  text-align: center;
  margin: 10px;
}
.popularBrandsContainer img {
  max-width: 130px;
  height: auto;
  transition: transform 0.3s ease; /* Smooth transition */
}

.popularBrandsContainer a:hover img {
  transform: scale(1.1); /* Enlarge image on hover */
}

.customSectionHeading {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.customSectionHeading h3 {
  color: black;
}

/**
Carousel Classes
**/

.mobileCaroButton {
  display: none;
  position: absolute;
  bottom: 10%;
  left: 50%;
  /* top: 50%; */
  transform: translateX(-50%);

  /* align it in the center horizontally */
  border: 0;
  background-color: black;
  color: var(--marketplaceColor);
  padding: 8px 40px;
  border-radius: 20px;
  text-transform: capitalize;
  font-weight: bolder;
  font-size: 14px;
}

.mobileCaroButton:hover {
  text-decoration: none;
  color: white;
}

.mobileCaroButtonSecondary {
  display: none;
  position: absolute;
  bottom: 10%;
  text-align: center;

  left: 50%;
  /* top: 50%; */
  transform: translateX(-50%);

  /* align it in the center horizontally */
  border: 0;
  background-color: var(--marketplaceColor);
  color: black;
  padding: 8px 30px;
  border-radius: 20px;
  text-transform: capitalize;
  font-weight: bolder;
  font-size: 14px;
}

.aboutUsSlide {
  left: calc(50% - 90px);
}

.mobileCaroButtonSecondary:hover {
  text-decoration: none;
  color: white;
}

/* Medium screens (tablets, 768px and up) */
@media (max-width: 1024px) {
  .popularBrandsContainer div {
    flex: 1 1 30%; /* Three columns */
  }
  .popularBrandsContainer img {
    max-width: 150px; /* Increase the size of images */
  }
}

/* Small screens (phones, 600px and up) */
@media (max-width: 768px) {
  .caroImageDesktop {
    display: none;
  }
  .caroImageMobile {
    display: block;
  }
  .mobileCaroButtonSecondary,
  .mobileCaroButton {
    display: block;
  }
  .popularBrandsContainer div {
    flex: 1 1 45%; /* Two columns */
  }
  .popularBrandsContainer img {
    max-width: 150px; /* Increase the size of images */
  }
  .caro {
    width: 100vw;
  }
}

/* Extra small screens (less than 600px) */
@media (max-width: 600px) {
  .caroImageDesktop {
    display: none;
  }
  .mobileCaroButtonSecondary,
  .mobileCaroButton {
    display: block;
  }
  .popularBrandsContainer div {
    flex: 1 1 100%; /* One column */
  }

  .popularBrandsContainer img {
    max-width: 150px; /* Increase the size of images */
  }
  .caro {
    width: 100vw;
  }
}

.listingCaro {
  padding-bottom: 40px;
  padding-left: 5px;
}

.listingCaroItem {
  padding: 50px;

  display: grid;
  /* grid-template-columns: repeat(1, 1fr); */
  /* gap: 10px; */
  width: 100%;
  margin-bottom: 50px;

  justify-content: center;
  align-items: center;
}

.listingCard {
  width: 95%;
}

.newestArrivalsSection {
  margin-top: 50px;
  margin-bottom: 50px;
}

.newestArrivalsSection h3 {
  text-align: center;
  color: black;
}

.whitebackground {
  background-color: white;
}

.listingsUnder50Section {
  margin-top: 50px;
  margin-bottom: 50px;
}

.listingsUnder50Section h3 {
  text-align: center;
  color: black;
}
